<template>
  <div class="font-montserrat">
    <div
      class="
        flex
        justify-center
        items-center
        py-6
        fixed
        inset-0
        z-40
        outline-none
        focus:outline-none
      "
    >
      <div class="absolute w-auto bg-none rounded top-0 right-0 mt-10 mr-10">
        <img
          src="@/app/ui/assets/svg/close-white.svg"
          class="w-4 cursor-pointer"
          @click="onClose"
          alt="x"
        />
      </div>

      <Splide
        :options="{
          rewind: true,
          type: 'fade',
          lazyLoad: 'nearby',
          focus: 'center',
          classes: {
            prev: 'splide__arrow--prev prev-arrow',
            next: 'splide__arrow--next next-arrow'
          }
        }"
        class="mt-5 w-1/2"
        style="height: 700px;"
      >
        <SplideSlide v-for="(item, index) in data" :key="index">
          <div class="flex justify-center">
            <img
              :src="item"
              style="height: 660px"
              class="w-full object-contain cursor-pointer"
              alt=""
              @click="clickDetail(item)"
            />
          </div>
        </SplideSlide>
      </Splide>
    </div>
    <div class="opacity-50 fixed inset-0 z-30 bg-black"></div>
  </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";

@Options({
  name: "PreviewImage",
  props: {
    onClose: {
      default: Function,
      type: Function
    },
    data: {
      default: [],
      type: Array
    }
  }
})
export default class extends Vue {
  clickDetail(item) {
    window.open(item);
  }
}
</script>

<style>
.splide__pagination__page {
  display: inline-block;
  width: 10px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  transition: transform 0.2s linear;
  border: none;
  @apply mx-2;
}

.splide__pagination__page.is-active {
  transform: scale(1.4);
  width: 30px;
  height: 8px;
  border-radius: 6px;
  background: #ffffff;
  @apply opacity-100;
}

.splide__pagination__page:hover {
  cursor: pointer;
}

.prev-arrow {
  width: 56px;
  height: 56px;
  background-image: url("../../../../../assets/svg/prev-arrow.svg");
  @apply opacity-100;
}

.next-arrow {
  width: 56px;
  height: 56px;
  background-image: url("../../../../../assets/svg/next-arrow.svg");
  @apply opacity-100;
}
</style>
